<template>
  <!-- 主体内容 -->
  <el-container class="workbench-main">
    <el-header>
      <div class="cur-nav">
        <img src="../../../public/img/logo.png" alt="" />
        <span>工作台</span>
        <i class="el-icon-close"></i>
      </div>
    </el-header>
    <el-main>
      <!-- 常用应用 -->
      <div class="common-fun">
        <div class="common-l">
          <!-- <img src="../../../public/img/workbench-look-img.png" alt="" /> -->
          <el-image :src="require('../../../public/img/workbench-look-img.png')" fit="fill"></el-image>
        </div>
        <div class="common-r">
          <lis-item v-for="(item, index) in commonFuns" :info="item" :key="index" @click.native="specialCourse"></lis-item>
          <!-- 添加 -->
          <lis-item :info="addFuns"></lis-item>
        </div>
      </div>
      <!-- 全部应用 -->
      <div class="all-fun">
        <div class="all-h">
          <div v-for="(item,index) in allFunsTit" :key="index" :class="{cur:currentIndex == index}" @click="allFunsTitChange(index)">{{item}}</div>
        </div>
        <div class="all-b">
          <lis-item v-for="(item, index) in allFuns" :info="item" :key="index" @click.native="specialCourse"></lis-item>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import LisItem from "@/components/context/workbench/LisItem";
export default {
  components: {
    LisItem
  },
  data() {
    return {
      currentIndex:0,//全部应用标题index
      // 添加应用
      addFuns:{
        icon: '../../../../public/img/add-ico.jpg',
        bgc: 'rgba(0, 0, 0, 0.1)',
        txt: '添加常用',
        open:false,
      },
      // 常用应用
      commonFuns: [
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%)',
          txt: '选优评优',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%)',
          txt: '选优评优',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%)',
          txt: '异常报表',
          open:false,
        },
         {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%)',
          txt: '机修报表',
          open:false,
        },
      ],
      allFuns: [
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(43deg, #504DEA 0%, #4087F9 100%)',
          txt: '生产任务',
          open:true,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%)',
          txt: '工资查询',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #FFA834 0%, #FF8C16 100%)',
          txt: '异常提报',
          open:false,
        },
         {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #00D1BE 0%, #2DF866 100%)',
          txt: '设备异常',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #00D1BE 0%, #2DF866 100%)',
          txt: '设备异常',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #00D1BE 0%, #2DF866 100%)',
          txt: '设备异常',
          open:false,
        },
        {
          icon: '../../../../public/img/heart-ico.png',
          bgc: 'linear-gradient(47deg, #00D1BE 0%, #2DF866 100%)',
          txt: '设备异常',
          open:false,
        },
      ],
      
      // 全部应用标题分类
      allFunsTit:['全部应用','查询服务','工作协同','实时数据','智能报表','沙发制造工学院','系统设置'],

    };
  },
  methods:{
    // 全部应用标题切换
    allFunsTitChange(index){
      this.currentIndex = index;
    },
    // 专题课程
    specialCourse(){
      this.$router.push({
        path:'/workbench/courseFilter',
        query:{

        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
.workbench-w {
  width: 100%;
  min-height: 100vh;
  .el-header {
    padding: 0;
  }
  .workbench-con {
    display: flex;
    height: 100%;
    .el-aside {
      width: 64px !important;
      background: linear-gradient(360deg,#26304d 0%,#1f2740 29%,#3c4b6c 100%);
    }
    .workbench-main {
      flex: 1;
      .el-header {
        height: 40px;
        background-color: #eee;
        line-height: 40px;
        border-bottom: 1px solid #eee;
        .cur-nav {
          width: 181px;
          height: 100%;
          background: #f8f9fa;
          border-radius: 8px 8px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #1e1e1e;
          position: relative;
          img {
            width: 30px;
            margin-right: 10px;
          }
          i {
            position: absolute;
            right: 8px;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.4);
            cursor: pointer;
          }
        }
      }
      .el-main {
        padding: 23px 0 23px 23px !important;
        background-color: #f8f9fa;
        .common-fun {
          display: flex;
          .common-l {
            margin-right: 30px;
            width: 584px;
            height: 202px;
            // img {
            //   width: 584px;
            //   height: 202px;
            // }
          }
          .common-r {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .lis-item{
              width: 24%;
              margin-right: calc( 4% / 4);
            }
            @media screen and (max-width: 1367px){
              .lis-item{
                width: 32%;
                margin-right: calc( 4% / 3);
              }
            }
          }
        }
        .all-fun{
          margin-top: 32px;
          .all-h{
            display: flex;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #D0D0D0;
            div{
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.8);
              margin-right: 24px;
              cursor: pointer;
            }
            .cur{
              color: #3370FF;
              border-bottom: 2px solid #3370FF;
            }
          }
          .all-b{
            padding:23px 0;
            display: flex;
            flex-wrap: wrap;
            .lis-item{
              width: 16%;
              margin-right: calc( 4% / 6);
            }
            // @media screen and (max-width: 1366px){
            //   .lis-item{
            //     width: 189px;
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>
