<template>
  <div class="lis-item">
    <div class="item-l" :style="{ background: info.open ? info.bgc: 'rgba(0, 0, 0, 0.1)' }">
      <!-- <img :src="info.icon" alt="" /> -->
      <img src="../../../../public/img/heart-ico.png" alt="" />
    </div>
    <div class="item-r">{{ info.txt }}</div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
<style scoped lang="scss">
.lis-item {
  display: flex;
  align-items: center;
  width: 280px;
  height: 84px;
  background: #ffffff;
  box-shadow: 1px 3px 8px 1px rgba(50, 107, 239, 0.2);
  border-radius: 8px;
  padding: 16px 13px;
  box-sizing: border-box;
  // margin-right: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  .item-l {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
    }
  }
  .item-r {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
