import { render, staticRenderFns } from "./LisItem.vue?vue&type=template&id=649c4f58&scoped=true&"
import script from "./LisItem.vue?vue&type=script&lang=js&"
export * from "./LisItem.vue?vue&type=script&lang=js&"
import style0 from "./LisItem.vue?vue&type=style&index=0&id=649c4f58&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649c4f58",
  null
  
)

export default component.exports